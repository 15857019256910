import React, {useEffect, useState} from "react";
import "./_landingPage.scss";
import FirstPart from "./FirstPart";
import TroupeHistoryMobile from "./TroupeHistoryMobile";
import TroupeHistory from "./TroupeHistory";
import LastPart from "./LastPart";

export default function LandingPage() {
  const [width, setWidth] = useState(800);

  useEffect(() => {
    window.addEventListener("resize", Update);
    Update();
    return () => window.removeEventListener("resize", Update);
  }, []);

  function Update() {
    setWidth(window.innerWidth);
  }

  return (
    <div className={"landingPage"}>
      <FirstPart />

      {/*width < 945 ? <TroupeHistoryMobile /> : <TroupeHistory />*/}

      <LastPart />
      <div className={"bottomLandingPage"}>
        <p>® Troupe 1er Chantilly, 2024, Tous droits réservés </p>
      </div>
    </div>
  );
}
