import React from "react";
import {
  BlueButton,
  DoubleInput,
  HeaderStep,
  MultipleChoice,
  SingleSelect,
} from "../ComponentsForm";

export default function InfosScouts({
  submitForm,
  entryYearScouts,
  exitYearScouts,
  haveBeenScout,
  setEntryYearScouts,
  setExitYearScouts,
  setHaveBeenScout,
  scoutsResponsabilitiesSelected, setScoutsResponsabilitiesSelected
}) {
  const scoutsResponsabilities = [
    "Chef de patrouille",
    "Assistant chef de troupe",
    "Chef de troupe",
  ];

  return (
    <form className={"step"} onSubmit={submitForm}>
      <HeaderStep
        title={"Avez-vous été aux Scouts ?"}
        subtitle={"Veuillez remplir les champs ci-dessous."}
      />

      <SingleSelect
        title={"Avez-vous été Scout ?"}
        placeholder={"Ex. 2010"}
        value={haveBeenScout}
        setValue={setHaveBeenScout}
        options={[
          { value: true, label: "Oui" },
          { value: false, label: "Non" },
        ]}
      />

      {haveBeenScout.toString() === 'true' && (
        <DoubleInput
          placehodlerFirstInput={"Ex. 2012"}
          placehodlerSecondInput={"Ex.2015"}
          firstInput={entryYearScouts}
          secondInput={exitYearScouts}
          setFirstInput={setEntryYearScouts}
          setSecondInput={setExitYearScouts}
          titleFirstInput={"Année d'arrivée"}
          titleSecondInput={"Année de départ"}
        />
      )}

        {haveBeenScout.toString() === 'true' && (
            <MultipleChoice
                title={"Avez-vous occupé une ou plusieurs de ces responsabilités ?"}
                choices={scoutsResponsabilities}
                choicesSelected={scoutsResponsabilitiesSelected}
                setChoicesSelected={setScoutsResponsabilitiesSelected}
              />
        )}

      <BlueButton
        text={"Valider les informations"}
        disabled={haveBeenScout.toString() === 'true' && scoutsResponsabilitiesSelected.length < 1}
      />
    </form>
  );
}
