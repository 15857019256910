import React, { useState } from "react";
import {
  BlueButton,
  DoubleInput,
  HeaderStep,
  MultipleChoice,
  SingleInput,
  SingleSelect,
} from "../ComponentsForm";

export default function InfosLouveautaux({
  submitForm,
  entryYearLouveteaux,
  exitYearLouveteaux,
  haveBeenLouveteau,
  setEntryYearLouveteaux,
  setExitYearLouveteaux,
  setHaveBeenLouveteau,
  width,
}) {

  return (
    <form className={"step"} onSubmit={submitForm}>
      <HeaderStep
        title={"Avez-vous été aux Louveteaux ?"}
        subtitle={"Veuillez remplir les champs ci-dessous."}
      />

      <SingleSelect
        title={"Avez-vous été Louveteau ?"}
        placeholder={"Ex. 2010"}
        value={haveBeenLouveteau}
        setValue={setHaveBeenLouveteau}
        options={[
          { value: true, label: "Oui" },
          { value: false, label: "Non" },
        ]}
      />

      {haveBeenLouveteau.toString() === 'true' && (
        <DoubleInput
          placehodlerFirstInput={"Ex. 2012"}
          placehodlerSecondInput={"Ex.2015"}
          firstInput={entryYearLouveteaux}
          secondInput={exitYearLouveteaux}
          setFirstInput={setEntryYearLouveteaux}
          setSecondInput={setExitYearLouveteaux}
          titleFirstInput={"Année d'arrivée"}
          titleSecondInput={"Année de départ"}
        />
      )}
      <BlueButton
        text={"Valider les informations"}
      />
    </form>
  );
}
