import React from "react";
import {
  BlueButton,
  HeaderStep,
  InfosSquare,
  RIB,
  SingleSelect,
} from "../ComponentsForm";

export default function Participate({
  submitForm,
  participation,
  setParticipation,
  participationNumber,
  setParticipationNumber,
}) {
  return (
    <form className={"step"} onSubmit={submitForm}>
      <HeaderStep
        title={"Participation à l’événement"}
        subtitle={"Veuillez remplir les champs ci-dessous."}
      />

      <SingleSelect
        title={"Participerez vous à l’événement ?"}
        placeholder={"Ex. 2010"}
        value={participation}
        setValue={setParticipation}
        options={[
          { value: true, label: "Oui" },
          { value: false, label: "Non" },
        ]}
      />

      {participation.toString() === "true" && (
        <SingleSelect
          title={"Combien serez-vous à nous rejoindre ?"}
          placeholder={"4"}
          value={participationNumber}
          setValue={setParticipationNumber}
          options={[
            { value: 1, label: "1" },
            { value: 2, label: "2" },
            { value: 3, label: "3" },
            { value: 4, label: "4" },
            { value: 5, label: "5" },
            { value: 6, label: "6" },
            { value: 7, label: "7" },
            { value: 8, label: "8" },
            { value: 9, label: "9" },
            { value: 10, label: "10" },
            { value: 11, label: "11" },
            { value: 12, label: "12" },
            { value: 13, label: "13" },
            { value: 14, label: "14" },
          ]}
        />
      )}

      <InfosSquare
        title={
          participation.toString() === "true"
            ? "Participation financière"
            : "Faire un don au Groupe"
        }
        subtitle={
          "Afin de rendre cet événement possible, la troupe \n" +
          "fait appel à la contribution libre de chaque participant. \n" +
          "Nous recommandons 10 € par personne ou 20 € par famille. Vous trouverez ci-joint les \n" +
          "informations bancaires de la troupe."
        }
      />

      <BlueButton
        text={
          participation.toString() === "true"
            ? "Participer à l'événement"
            : "Soumettre le formulaire"
        }
      />
    </form>
  );
}
