// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBDV6AvIJlwUNoryM4wJc2ed-peynVsOj4",
    authDomain: "troupe-6886f-32f3e.firebaseapp.com",
    projectId: "troupe-6886f",
    storageBucket: "troupe-6886f.appspot.com",
    messagingSenderId: "504037042731",
    appId: "1:504037042731:web:b865886923988eabf8186e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const storage = getStorage(app);

