import React from "react";
import {
  BlueButton,
  HeaderStep,
  InfosSquare,
  SingleInput,
} from "../ComponentsForm";

export default function Remarques({
  submitForm,
  setPhotos,
  notes,
  setNotes,
  width,
  photos,
}) {
  return (
    <form className={"step"} onSubmit={submitForm}>
      <HeaderStep
        title={"Remarques"}
        subtitle={"Veuillez remplir les champs ci-dessous"}
      />

      <SingleInput
        title={"Souhaitez-vous nous transmettre un commentaire ? "}
        placeholder={"Rédigez votre commentaire ici…"}
        value={notes}
        setValue={setNotes}
        isTextArea={true}
        notRequired={true}
      />

      <InfosSquare
        photos={photos}
        setPhotos={setPhotos}
        isAddPictures={true}
        title={"Partager des Photos"}
        subtitle={
          "N’hésitez pas à partager des photos que vous auriez \n" +
          "prises de la troupe au cours des dernières années. \n" +
          "Ces dernières nous aideront à créer un événement \n" +
          "mémorable pour célébrer les 50 ans de la troupe."
        }
      />

      <BlueButton text={"Étape suivante"} />
    </form>
  );
}
