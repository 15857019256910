import React from "react";
import {
  BlueButton,
  DoubleInput,
  HeaderStep,
  SingleInput,
  SingleSelect,
} from "../ComponentsForm";

export default function InfosPersos2({
  submitForm,
  nombreEnfants,
  setNombreEnfants,
  situationMatrimoniale,
  setSituationMatrimoniale,
  birthDate,
  setBirthDate,
  width,
}) {
  const optionsMatrimonial = [
    { value: "choose", label: "Choisir" },
    { value: "celibataire", label: "Célibataire" },
    { value: "fiance", label: "Fiancé" },
    { value: "marie", label: "Marié" },
    { value: "veuf", label: "Veuf" },
    { value: "clerge", label: "Clergé / Vœux religieux" },
  ];

  const optionsNombreEnfants = [
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
  ];

  return (
    <form className={"step"} onSubmit={submitForm}>
      <HeaderStep
        title={"Informations personnelles"}
        subtitle={"Veuillez remplir les champs ci-dessous."}
      />
      <SingleInput
        title={"Date de naissance"}
        type={"date"}
        value={birthDate}
        setValue={setBirthDate}
      />

      <SingleSelect
        title={"Situation matrimoniale"}
        options={optionsMatrimonial}
        setValue={setSituationMatrimoniale}
      />

      {situationMatrimoniale.toString() === "marie" && (
        <SingleSelect
          title={"Nombre d'enfants"}
          options={optionsNombreEnfants}
          value={nombreEnfants}
          setValue={setNombreEnfants}
        />
      )}
      <BlueButton
        text={"Valider les informations"}
        disabled={birthDate.length === 0 || situationMatrimoniale.length === 0}
      />
    </form>
  );
}
