import React from "react";
import { BlueButton, HeaderStep, InfosSquare } from "../ComponentsForm";

export default function Welcome({ submitForm }) {
  return (
    <form className={"step"} onSubmit={submitForm}>
      <HeaderStep
        title={"Formulaire d’inscription"}
        subtitle={
          "L’ensemble de l’équipe du 1er Chantilly Groupe d’Aumale a \n" +
          "besoin de vos informations pour organiser au mieux le \n" +
          "rassemblement célébrant les 50 ans de la troupe."
        }
        secondSubtitle={
          "Nous vous remercions de remplir le questionnaire suivant. \n" +
          "Vos réponses nous seront d’une grande aide dans \n" +
          "l’organisation de cet événement."
        }
      />

      <InfosSquare
        title={"Informations pratique"}
        subtitle={"L'évènement se déroulera le 6 juillet 2024 à Chantilly."}
      />
      <BlueButton text={"S'inscrire à l'évènement"} subtitle={"Envion 3 min"} />
    </form>
  );
}
