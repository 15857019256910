import React from "react";
import { BlueButton, HeaderStep, InfosSquare } from "../ComponentsForm";
import "../_componentsForm.scss";
export default function Remerciement() {
  return (
    <div className={"step"}>
      <HeaderStep
        title={"Merci d’avoir complété notre \n" + "questionnaire !"}
        subtitle={
          "L’ensemble de la troupe du 1er Chantilly, Groupe d’Aumale, \n" +
          "vous remercie d’avoir complété le questionnaire et de rendre \n" +
          "ainsi possible l’événement des 50 ans de la troupe."
        }
      />

      <InfosSquare
        link={
          "https://www.helloasso.com/associations/agse-groupe-1re-chantilly/evenements/50-ans-1ere-chantilly"
        }
        subtitle={"Cliquez sur le lien pour faire un don au groupe."}
        title={"Lien Hello Asso pour faire un don"}
        openLink={true}
        bottom={30}
      />

      <InfosSquare
        link={"https://groupe1chantilly.fr"}
        title={"Partager le questionnaire"}
        subtitle={
          "N’hésitez pas à partager ce questionnaire auprès \n" +
          "de vos proches ou amis qui souhaiteraient participer \n" +
          "aux 50 ans de la troupe. Chaque réponse compte, \n" +
          "nous vous remercions donc pour ce partage."
        }
      />

      <p className={"contact"}>
        Vous pouvez nous contacter à l'adresse :{" "}
        <a href="mailto:50ans.groupe1.chantilly@gmail.com">
          50ans.groupe1.chantilly@gmail.com
        </a>
      </p>
    </div>
  );
}
