import React, { useEffect, useState } from "react";
import flag from "../../assets/images/flag.png";
import imgTroupe from "../../assets/images/imgTroupe.png";
import imgLouvetaux from "../../assets/images/imgLouvetaux.png";
import { db } from "../../firebase/config";
import { collection, query, getCountFromServer } from "firebase/firestore";

export default function LastPart() {
  const [participantsNumber, setParticipantsNumber] = useState(0);

  useEffect(() => {
    const q = query(collection(db, "formulaires"));
    getCountFromServer(q).then((snapshot) => {
      setParticipantsNumber(snapshot.data().count);
    });
  }, []);

  const ContactInfo = () => {
    return (
      <div className={"containerContactInfos"}>
        {/* Colonne 1 */}
        <div>
          <h4>Chef de groupe</h4>
          <p>Charles de Bailliencourt</p>
          <p>06 10 36 28 59</p>
        </div>

        {/* Colonne 2 */}
        <div>
          <h4>Chef de troupe</h4>
          <p>Louis Neltner</p>
          <p>06 21 42 95 90</p>
        </div>

        {/* Colonne 3 */}
        <div>
          <h4>Akela</h4>
          <p>Côme des Coutils</p>
          <p>07 66 46 39 49</p>
        </div>
      </div>
    );
  };

  return (
    <div className={"containerLastPart"}>
      <div className={"lastPart"}>
        <p className={"aPropos"}>A propos du</p>
        <h2 className={"titleLastPart"}>
          Cinquantenaire de la troupe 1er Chantilly
        </h2>
        <ContactInfo />
        <a href="/formulaire">
          <div className={"participateEvent"}>PARTICIPER À L’ÉVÉNEMENT</div>
        </a>
        <p className={"participantsEvent"}>
          Rejoignez les {participantsNumber} participants de l’événement{" "}
        </p>
        <div className={"imagesLastPart"}>
          <img src={flag} alt="" className={"imgLastPart"} />
          <img src={imgTroupe} alt="" className={"imgLastPart"} />
          <img src={imgLouvetaux} alt="" className={"imgLastPart"} />
        </div>
      </div>
    </div>
  );
}
