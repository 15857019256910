import React, { useState } from "react";
import {
  BlueButton,
  DoubleInput,
  HeaderStep,
  MultipleChoice,
  SingleInput,
  SingleSelect,
} from "../ComponentsForm";

export default function InfosRoutiers({
  submitForm,
  haveBeenRoutier,
  exitYearRoutiers,
  entryYearRoutiers,
  setEntryYearRoutiers,
  setExitYearRoutiers,
  setHaveBeenRoutier,
}) {
  return (
    <form className={"step"} onSubmit={submitForm}>
      <HeaderStep
        title={"Avez-vous été aux Routiers ?"}
        subtitle={"Veuillez remplir les champs ci-dessous."}
      />

      <SingleSelect
        title={"Avez-vous été Routier ?"}
        placeholder={"Ex. 2010"}
        value={haveBeenRoutier}
        setValue={setHaveBeenRoutier}
        options={[
          { value: true, label: "Oui" },
          { value: false, label: "Non" },
        ]}
      />

      {haveBeenRoutier.toString() === 'true' && (
        <DoubleInput
          placehodlerFirstInput={"Ex. 2012"}
          placehodlerSecondInput={"Ex.2015"}
          firstInput={entryYearRoutiers}
          secondInput={exitYearRoutiers}
          setFirstInput={setEntryYearRoutiers}
          setSecondInput={setExitYearRoutiers}
          titleFirstInput={"Année d'arrivée"}
          titleSecondInput={"Année de départ"}
        />
      )}

      <BlueButton
        text={"Valider les informations"}
      />
    </form>
  );
}
