import React from "react";
import leftIcon from "../../assets/icons/leftIcon.svg";

export default function HeaderHomePage({ step, setStep }) {
  return (
    <div className={"headerForm"}>
      <div
        style={{ opacity: step === 10 ? 0.4 : 1 }}
        className={"buttonBack"}
        onClick={() => {
          if (step < 10) {
            setStep(step - 1);
          }
        }}
      >
        <img src={leftIcon} className={"leftIcon"} alt="" /> Retour à l'étape
        précédente
      </div>

      <div className={"listStepsIndicator"}>
        <div
          className={"stepIndicator"}
          style={{ backgroundColor: step === 2 ? "#4E66E4" : "#2E9F2D" }}
        />
        <div
          className={"stepIndicator"}
          style={{
            backgroundColor:
              step < 3 ? "#EAEAEA" : step > 3 ? "#2E9F2D" : "#4E66E4",
          }}
        />
        <div
          className={"stepIndicator"}
          style={{
            backgroundColor:
              step < 4 ? "#EAEAEA" : step > 4 ? "#2E9F2D" : "#4E66E4",
          }}
        />
        <div
          className={"stepIndicator"}
          style={{
            backgroundColor:
              step < 5 ? "#EAEAEA" : step > 5 ? "#2E9F2D" : "#4E66E4",
          }}
        />
        <div
          className={"stepIndicator"}
          style={{
            backgroundColor:
              step < 6 ? "#EAEAEA" : step > 6 ? "#2E9F2D" : "#4E66E4",
          }}
        />
        <div
          className={"stepIndicator"}
          style={{
            backgroundColor:
              step < 7 ? "#EAEAEA" : step > 7 ? "#2E9F2D" : "#4E66E4",
          }}
        />
        <div
          className={"stepIndicator"}
          style={{
            backgroundColor:
              step < 8 ? "#EAEAEA" : step > 8 ? "#2E9F2D" : "#4E66E4",
          }}
        />
        <div
          className={"stepIndicator"}
          style={{
            backgroundColor:
              step < 9 ? "#EAEAEA" : step > 8 ? "#2E9F2D" : "#4E66E4",
          }}
        />
      </div>
    </div>
  );
}
