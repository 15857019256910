import React from "react";
import {
  BlueButton,
  DoubleInput,
  HeaderStep,
  SingleInput,
} from "../ComponentsForm";

export default function InfosPerso({
  submitForm,
  address,
  email,
  name,
  phone,
  setAddress,
  setEmail,
  setName,
  setPhone,
  setFirstName,
  firstName,
  width,
}) {
  return (
    <form className={"step"} onSubmit={submitForm}>
      <HeaderStep
        title={"Informations personnelles"}
        subtitle={"Veuillez remplir les champs ci-dessous."}
      />
      <DoubleInput
        placehodlerFirstInput={"Richard"}
        placehodlerSecondInput={"Louis"}
        firstInput={name}
        secondInput={firstName}
        setFirstInput={setName}
        setSecondInput={setFirstName}
        titleFirstInput={"Nom"}
        titleSecondInput={"Prénom"}
      />
      <SingleInput
        title={"Téléphone portable"}
        placeholder={"0769094854"}
        value={phone}
        setValue={setPhone}
        type={"phone"}
      />

      <SingleInput
        title={"Adresse mail"}
        placeholder={"louis.richard@scouts-europe.com"}
        value={email}
        setValue={setEmail}
        type={"email"}
      />

      <SingleInput
        title={"Adresse postale"}
        placeholder={"3 rue Saint Joseph, 6050 Chantilly"}
        value={address}
        setValue={setAddress}
      />
      <BlueButton
        text={"Valider les informations"}
        disabled={
          name.length === 0 ||
          firstName.length === 0 ||
          phone.length === 0 ||
          email.length === 0 ||
          address.length === 0
        }
      />
    </form>
  );
}
