import React from "react";
import {
  BlueButton,
  DoubleInput,
  HeaderStep,
  SingleInput,
} from "../ComponentsForm";
import {hasFormSubmit} from "@testing-library/user-event/dist/utils";

export default function LeGroupeEtVous({
   submitForm,
  entryYear,
  exitYear,
  setEntryYear,
  setExitYear,
  width,
}) {
  return (
    <form className={"step"} onSubmit={submitForm}>
      <HeaderStep
        title={"Le Groupe & Vous"}
        subtitle={"Veuillez remplir les champs ci-dessous."}
      />

      <SingleInput
        title={"Année d'entrée dans le groupe"}
        placeholder={"Ex. 2010"}
        value={entryYear}
        setValue={setEntryYear}
      />

      <SingleInput
        title={"Année de départ du groupe"}
        placeholder={"Ex. 2015"}
        value={exitYear}
        setValue={setExitYear}
      />

      <BlueButton
        text={"Valider les informations"}
      />
    </form>
  );
}
