import React, { useRef, useState } from "react";
import logoScoutEurope from "../assets/icons/logoScoutEurope.png";
import leftArrow from "../assets/icons/leftArrow.svg";
import "./_componentsForm.scss";

function Spacer({ height }) {
  return <div className={"spacer"} style={{ height: height }}></div>;
}

export function HeaderStep({ title, subtitle, secondSubtitle }) {
  return (
    <div className={"headerStep"}>
      <img src={logoScoutEurope} alt="" className={"logoScoutEurope"} />
      <p className={"troupName"}>Troupe 1er Chantilly, Groupe d’Aumale</p>
      <p className={"mainTitle"}>{title}</p>
      <p className={"subtitle"}>{subtitle}</p>
      <p className={"subtitle"}>{secondSubtitle}</p>
    </div>
  );
}

export function BlueButton({ text, subtitle, disabled }) {
  return (
    <div className={"containerButton"} style={{ opacity: disabled ? 0.4 : 1 }}>
      <button className={"blueButton"} disabled={disabled}>
        {text}
      </button>
      <p className={"subtitleButton"}>{subtitle}</p>
    </div>
  );
}

export function DoubleInput({
  placehodlerFirstInput,
  placehodlerSecondInput,
  titleFirstInput,
  titleSecondInput,
  firstInput,
  secondInput,
  setFirstInput,
  setSecondInput,
}) {
  return (
    <div className={"doubleInput"}>
      <div className={"containerLittleInput"}>
        <p className={"titleInput"}>
          {titleFirstInput} <span style={{ color: "#EA3322" }}>*</span>
        </p>
        <input
          placeholder={placehodlerFirstInput}
          type="text"
          value={firstInput}
          onChange={(e) => setFirstInput(e.target.value)}
          className={"littleInput"}
          required={true}
        />
      </div>

      <div className={"containerLittleInput"}>
        <p className={"titleInput"}>
          {titleSecondInput} <span style={{ color: "#EA3322" }}>*</span>
        </p>
        <input
          placeholder={placehodlerSecondInput}
          type="text"
          value={secondInput}
          onChange={(e) => setSecondInput(e.target.value)}
          className={"littleInput"}
          required={true}
        />
      </div>
    </div>
  );
}

export function SingleInput({
  placeholder,
  title,
  value,
  setValue,
  type,
  isTextArea,
  notRequired,
}) {
  return (
    <div className={"singleInput"}>
      <p className={"titleInput"}>
        {title} <span style={{ color: "#EA3322" }}>*</span>
      </p>
      {isTextArea ? (
        <textarea
          name=""
          id=""
          cols="30"
          rows="10"
          placeholder={placeholder}
          style={{ height: 100 }}
          className={"input"}
          required={!notRequired}
        ></textarea>
      ) : (
        <input
          placeholder={placeholder}
          type={type ? type : "text"}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className={"input"}
          required={true}
        />
      )}
    </div>
  );
}

export function SingleSelect({ options, title, setValue, value }) {
  return (
    <div className={"singleInput"}>
      <p className={"titleInput"}>
        {title} <span style={{ color: "#EA3322" }}>*</span>
      </p>
      <div style={{ display: "flex", alignItems: "center" }}>
        <select
          value={value}
          onChange={(e) => setValue(e.target.value)}
          required={true}
        >
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <img src={leftArrow} alt="" className={"leftArrow"} />
      </div>
    </div>
  );
}

export function MultipleChoice({
  title,
  choices,
  choicesSelected,
  setChoicesSelected,
}) {
  function ChoiceItem({ name, setChoicesSelected, choicesSelected }) {
    const addChoice = () => {
      if (!choicesSelected.includes(name)) {
        setChoicesSelected((prevChoices) => [...prevChoices, name]);
      } else {
        setChoicesSelected((prevChoices) =>
          prevChoices.filter((choice) => choice !== name),
        );
      }
    };

    return (
      <div
        className={
          choicesSelected.includes(name) ? "choiceItemSelected" : "choiceItem"
        }
        onClick={addChoice}
      >
        {name}
        <span
          style={{
            color: choicesSelected.includes(name) ? "#EA3322" : "#4E66E4",
            fontSize: 13,
          }}
        >
          {choicesSelected.includes(name) ? "Retirer" : "Ajouter"}
        </span>
      </div>
    );
  }

  return (
    <div className={"multipleChoice"}>
      <p className={"titleInput"}>
        {title} <span style={{ color: "#EA3322" }}>*</span>
      </p>
      <div className={"listChoices"}>
        {choices.map((item, index) => (
          <ChoiceItem
            key={index}
            name={item}
            setChoicesSelected={setChoicesSelected}
            choicesSelected={choicesSelected}
          />
        ))}
      </div>
    </div>
  );
}

export function InfosSquare({
  title,
  subtitle,
  isAddPictures,
  link,
  photos,
  setPhotos,
  bottom,
  openLink,
}) {
  const handlePhotoChange = (event) => {
    if (event.target.files) {
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        const reader = new FileReader();
        reader.onloadend = () => {
          setPhotos((prevState) => [...prevState, reader.result]);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const [isCopied, setIsCopied] = useState(false);

  return (
    <div
      className={"infosSquare"}
      style={{ marginTop: link && 0, marginBottom: bottom }}
    >
      <p className={"titleInfosSquare"}>{title}</p>
      <p className={"subtitleInfosSquare"}>{subtitle}</p>

      {isAddPictures && (
        <>
          <input
            id={"fileInput"}
            type="file"
            accept="image/*, .heic, .heif"
            multiple
            onChange={handlePhotoChange}
            style={{ display: "none" }}
          />
          <label htmlFor={"fileInput"} className={"buttonImportPictures"}>
            Partager des photos
          </label>
        </>
      )}

      {photos?.length > 0 && (
        <div className={"photosContainer"}>
          {photos.map((photo, index) => (
            <img
              key={index}
              src={photo}
              alt={`Photo ${index + 1}`}
              className={"picture"}
            />
          ))}
        </div>
      )}

      {photos?.length > 0 && (
        <p className={"deletePictures"} onClick={() => setPhotos([])}>
          Supprimer les photos
        </p>
      )}

      {openLink ? (
        <a href={link} target={"_blank"}>
          <div
            className={"squareLink"}
            onClick={() => {
              setIsCopied(true);
              navigator.clipboard.writeText(link);
            }}
            style={{ color: "#2B4AD1" }}
          >
            {link}
          </div>
        </a>
      ) : (
        link && (
          <div
            className={"squareLink"}
            onClick={() => {
              setIsCopied(true);
              navigator.clipboard.writeText(link);
            }}
          >
            {link}
            <span
              style={{
                color: isCopied ? "#2d9b32" : "#2B4AD1",
                marginLeft: 10,
              }}
            >
              {isCopied ? "Copié !" : "Copier"}
            </span>
          </div>
        )
      )}

      {link === "https://groupe1chantilly.fr" && (
        <a
          href={link}
          style={{
            color: "#2B4AD1",
            fontWeight: 600,
            fontSize: 14,
            marginTop: -15,
            marginBottom: 15,
          }}
        >
          Retour au site internet
        </a>
      )}
    </div>
  );
}

export function RIB() {
  function Ligne({ designant, value }) {
    return (
      <div className={"ligeRib"}>
        {designant}
        <div className={"containerValueRIB"}>{value}</div>
      </div>
    );
  }

  return (
    <div className={"rib"}>
      <Ligne
        designant={"Identité du compte"}
        value={"1er Chantilly Groupe d’Aumale"}
      />
      <Ligne designant={"RIB"} value={"FR76  5678  9078  3457"} />
      <Ligne designant={"B.I.C"} value={"CRLYFRPP"} />
    </div>
  );
}
